import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import Sections from "../components/privacy-policy/sections"
import banner from "../images/available-dogs-banner-bg.jpg"

const PrivacyPolicyPage = () => {

    return <Layout colored_header={true}>
        <Seo title="Privacy Policy" />
        <InnerBanner theme="white" image={banner} title="Privacy Policy" />

        <Sections.Section1/>


        <HowToHelp />
    </Layout>
}

export default PrivacyPolicyPage